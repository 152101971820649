import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/fbert/OneDrive/Documents/wwwBertramFamilyCom/GatsbyJS/src/components/layout-mdx.js";
import { TwoCol, Main, Aside } from "../../../../components/felix-mdx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TwoCol mdxType="TwoCol">
      <Main mdxType="Main">
        <h2>{`Description`}</h2>
        <p>{`Eng-DB-2 is a light-weight engineering database. It allows to manage
components/assemblies and their associated AVLs and technical documentation,
assemble BOMs for finished goods and annotate these with quotations received
from suppliers.`}</p>
        <h2>{`Features`}</h2>
        <p>{`There are lots of commercial off-the-shelf PLM solutions available, including
but not limited to Arena, Omnify or Windchill. However, these solutions are
typically much more aimed at Operations than Engineering.`}</p>
        <p>{`Engineering’s requirements often differ from Operation’s requirements; mostly in
how to deal with incomplete, preliminary and inaccurate information. In order to
maintain productivity in Engineering, the following key requirements have been identified:`}</p>
        <ul>
          <li parentName="ul">{`fast and light-weight process for creating parts`}</li>
          <li parentName="ul">{`distinction between global library and product-specific parts`}</li>
          <li parentName="ul">{`ability to deal with placeholder part-numbers`}</li>
          <li parentName="ul">{`fast method for adjusting part information including AVLs and cost`}</li>
          <li parentName="ul">{`fast method for importing quotations received from vendors`}</li>
          <li parentName="ul">{`fast method for benchmarking quotations against targets`}</li>
          <li parentName="ul">{`full-text search for parts`}</li>
          <li parentName="ul">{`simple ‘where-used’ search`}</li>
        </ul>
        <p>{`It is well understood, that ultimately the data will need to go into a database
that is owned and maintained by Operations. This leads to another requirement:`}</p>
        <ul>
          <li parentName="ul">{`straightforward way of exporting the data for hand-off to Operations`}</li>
        </ul>
      </Main>
      <Aside mdxType="Aside">
        <p>{`Resources:`}</p>
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "BOM_builder.pdf"
            }}>{`documentation`}</a></li>
        </ul>
      </Aside>
    </TwoCol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      